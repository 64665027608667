<template>
  <v-timeline
    dense
    clipped
    align-top
  >
    <v-timeline-item
      v-for="(log, n) in entriesSliced"
      :key="n"
      class="mb-4"
      color="grey"
      small
      fill-dot
      :icon="log.icon"
    >
      <v-row justify="space-between">
        <v-col
          cols="7"
          class="pa-0"
        >
          {{ log.title }}
          <div class="caption">
            {{ log.content | moment('llll') }}
          </div>
        </v-col>
        <v-col
          class="text-right caption"
          cols="5"
        />
      </v-row>
    </v-timeline-item>
    <div
      v-if="entries.length > entriesSliced.length"
      v-intersect.quiet="loadMoreEntries"
    />
  </v-timeline>
</template>

<script>
export default {
	name: 'StaffTimeLine',
	props: {
		staff: {
			type: Object, 
			default: () => {}
		},
		work: {
			type: Array, 
			default: () => []
		}
	},
	data() {
		return {
			alignTop: false,
			avatar: false,
			dense: false,
			fillDot: false,
			hideDot: false,
			icon: false,
			iconColor: false,
			left: false,
			reverse: false,
			right: false,
			small: false,
			length: 50
		}
	},
	methods: {
		loadMoreEntries() {
			this.length = this.length + 50
		}
	},
	computed: {
		entriesSliced() {
			return this.entries.slice(0, this.length)
		},
		workSliced() {
			return this.work.slice(0, this.length)
		},
		entries() {
			const entries = []

			for(let i in this.workSliced) {
				let log = this.work[i]

				if(log.end_date) {
					entries.push({
						title: this.$t('clockedOut'),
						key: 'clock_out',
						icon: 'mdi-arrow-left-bold',
						content: log.end_date
					})
				}

				if(log.start_date) {
					entries.push({
						title: this.$t('clockedIn'),
						key: 'clock_in',
						icon: 'mdi-arrow-right-bold',
						content: log.start_date
					})
				}

			}

			return entries
		}
	},

}
</script>