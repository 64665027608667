<template>
  <div>
    <BetaWarning />
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <WorkList
          v-if="tab.key === 'work-list'"
          :work="work"
          :step="200"
          :staff="staff"
          @updatedWorkHours="updatedWorkHours"
        />
        <WorkHoursDetailed
          v-if="tab.key === 'work-summary'"
          :work="work"
          :staff="staff"
          @updatedWorkHours="updatedWorkHours"
        />
        <StaffProfile
          v-if="tab.key === 'profile'"
          :staff="staff"
          :work="work"
        />
        <StaffTasks
          v-if="tab.key === 'tasks'"
          :staff="staff"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import WorkHoursDetailed from "@/components/WorkHoursDetailed"
import StaffProfile from "@/components/StaffProfile"
import WorkList from "@/components/WorkList"
import BetaWarning from "@/components/common/BetaWarning"
import StaffTasks from "@/components/person/StaffTasks"

export default {
	name: 'Staff',
	components: {
		StaffTasks,
		BetaWarning,
		WorkHoursDetailed,
		StaffProfile,
		WorkList
	},
	data: function() {
		return {
			staff: null,
			work: []
		}
	},
	computed: {
		selectedAppBarTab: function() {
			return this.$store.state.selectedAppBarTab
		},
		tabs: function() {

			const tabs = []

			tabs.push({
				title: this.$t('profile'),
				key: 'profile'
			})

			tabs.push({
				title: this.$t('workList'),
				key: 'work-list'
			})

			tabs.push({
				title: this.$t('workSummary'),
				key: 'work-summary'
			})

			tabs.push({
				title: this.$t('tasks'),
				key: 'tasks'
			})

			return tabs
		}
	},
	beforeMount() {
		this.staff = this.$store.getters.getStaffByUUID(this.$route.params.uuid)
		this.$store.dispatch('getWorkByStaff', {
			uuid: this.$route.params.uuid,
			startTimeInMillis: 0,
			endTimeInMillis: Date.now()
		}).then(works => {
			this.work = works
			if(this.work) {
				this.work.sort((a,b) => b.start_date - a.start_date)
			}
		})
	},
	mounted() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
		this.$store.commit('updateAppBarTitle', this.staff.address.name_line)
	},
	methods: {
		updatedWorkHours(success) {
			if(success) {
				this.$store.dispatch('getWorkByStaff', {
					uuid: this.$route.params.uuid,
					startTimeInMillis: 0,
					endTimeInMillis: Date.now()
				}).then(works => {
					this.work = works
					if(this.work) {
						this.work.sort((a,b) => b.start_date - a.start_date)
					}
				})
			}
		}
	}
}

</script>