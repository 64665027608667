<template>
  <v-alert
    type="warning"
    icon="mdi-alert"
    class="white--text mb-0"
    rounded="false"
    tile
  >
    {{$t('pleaseNoteThatThisPageIsStillInABetaVersion')}}
  </v-alert>
</template>


<script>
    export default {
        name: "BetaWarning"
    }
</script>

<style scoped>

</style>