<template>
  <div>
    <v-row>
      <v-col>
        <staff-information
          :person="staff"
          :is-updating="isUpdatingStaff"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          :loading="isUpdatingStaff"
          :disabled="isUpdatingStaff || isSendingInvite"
          color="primary"
          x-large
          @click="updateStaff"
        >
          {{ $t('updateInformation') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          block
          :loading="isSendingInvite"
          :disabled="isSendingInvite || isUpdatingStaff"
          color="green"
          x-large
          @click="showSendWorkInviteDialog = !showSendWorkInviteDialog"
        >
          {{ $t('sendInviteToReviewWork') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          v-if="staffOnFile.active"
          block
          color="error"
          x-large
          :loading="isUpdatingStaff"
          :disabled="isUpdatingStaff || isSendingInvite"
          @click="updateStaffStatus(false)"
        >
          {{ $t('disable') }}
        </v-btn>
        <v-btn
          v-else
          block
          color="primary"
          x-large
          :loading="isUpdatingStaff"
          :disabled="isUpdatingStaff || isSendingInvite"
          @click="updateStaffStatus(true)"
        >
          {{ $t('enable') }}
        </v-btn>
      </v-col>
    </v-row>
    <StaffInviteReviewWorkDialog
      v-model="showSendWorkInviteDialog"
      v-if="showSendWorkInviteDialog"
      :staff="staff"
      @closed="closedSendWorkInviteDialog"
      @confirmed="confirmedSendWork"
    />
  </div>
</template>

<script>
import StaffInformation from "./person/StaffInformation"
import StaffInviteReviewWorkDialog from "@/components/person/StaffInviteReviewWorkDialog"

export default {
	name: 'StaffForm',
	components: {
		StaffInviteReviewWorkDialog,
		StaffInformation
	},
	props: {
		processing: {
			type: Boolean
		},
		staffOnFile: {
			type: Object, 
			default: () => {}
		}
	},
	data() {
		return {
			openPanel: 0,
			isSendingInvite: false,
			showSendWorkInviteDialog: false,
			staff: {
				address: {
					name_line: null,
				},
				phone: null,
				ssn: null,
				email: null,
				pin: null,
				allowed_discounts: null,
				is_pos_admin: null,
				standardRate: null,
				eveningRate: null,
				weekendRate: null,
				publicHolidayRate: null,
				majorPublicHolidayRate: null,
			}
		}
	},
	methods: {
		closedSendWorkInviteDialog() {
			this.showSendWorkInviteDialog = !this.showSendWorkInviteDialog
		},
		confirmedSendWork(settings) {
			this.showSendWorkInviteDialog = !this.showSendWorkInviteDialog
			this.sendWorkReviewInvite(settings)
		},
		updateStaffStatus(status) {
			const staff = {
				uuid: this.staffOnFile.uuid,
				active: status
			}
			this.$store.dispatch('updateStaffStatus', staff)
		},
		updateStaff() {
			const staff = this.staff
			staff.uuid = this.staffOnFile.uuid
			this.$store.dispatch('updateStaff', staff).then(result => {
				if(result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('staffInformationUpdated')
          })
				}
			})
		},
		sendWorkReviewInvite(settings) {
			this.isSendingInvite = true

			if(settings.optionalMessage) {
				settings.optionalMessage = settings.optionalMessage.replace(/$/gm, '<br>').replace(/(\r\n|\r|\n)/gm, '')
			}

			this.$store.dispatch('sendWorkReviewInvite', {
				staff: this.staff,
				optionalMessage: settings.optionalMessage,
			}).then(result => {
				if(result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('emailWasSent')
          })
				} else if(result === false) {

					let href = 'mailto:salescloud@salescloud.is'
					let subject = '?subject=Unable%20to%20send%20work%20review%20invite%20e-mail'
					let body = '&body=Regarding%20staff%20id:%20' + this.staff.uuid + ',%20name:%20' + this.staff.address.name_line
					href = href + subject + body
					let subMessage = 'Please make sure the staff has an e-mail address and a PIN.\n ' +
							'If everything seems to be in order please contact us via ' +
							'<a style="color: #f0f0f0; text-decoration: none;" href="' + href + '">salescloud@salescloud.is</a>'
          this.$store.commit('updateActionError', {
            message: this.$t('unableToSendInviteEmail'),
            subMessage: subMessage
          })
				}
			}).catch(() => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('unableToSendInviteEmail')
        })
			}).finally(() => {
				this.isSendingInvite = false
			})
		}
	},
	computed: {
		notReady() {
			return this.$store.state.notReady
		},
		isUpdatingStaff() {
			return this.$store.state.isUpdatingStaff
		},
		discountRange() {
			const array = []

			for(let i = 0; i <= 100; i++) {
				array.push(i)
			}

			return array
		}
	},
	beforeMount() {
		if(this.staffOnFile) {
			this.staff = this.staffOnFile
			this.resetStaff = JSON.parse(JSON.stringify(this.staffOnFile))
		}
	},
	beforeDestroy() {
		//
	},
	watch: {
		staffOnFile(newValue, oldValue) {
			if(newValue !== oldValue) {
				this.staff = this.staffOnFile
			}
		}
	}
}
</script>

<style>

</style>