<template>
  <v-container
    fluid
    fill-height
  >
    <v-row>
      <v-col
        cols="12"
        md="8"
        order="3"
        order-md="1"
      >
        <StaffTimeLine
          :staff="staff"
          :work="work"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        order="1"
        order-md="3"
      >
        <StaffForm
          :processing="true"
          :staff-on-file="staff"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import StaffForm from './StaffForm'
import StaffTimeLine from './StaffTimeLine'

export default {
	name: 'StaffProfile',
  props: {
    staff: {
      type: Object, 
      default: () => {}
    },
    work: {
      type: Array, 
      default: () => []
    }
  },
	components: {
		StaffForm,
		StaffTimeLine
	}
}
</script>